.landing-photo {
    width: 90%;
    max-width: 750px;

    margin-top: 30px;
    margin-bottom: 70px;
}

.bg-fill {
    height: 100%;
    padding-top: 90px;

    color: black;
}

.bg-fill a.btn.btn-lg {
    margin-bottom: 15px;
}

img.small-intro-img {
    width: 170px;
    margin: 20px 20px 50px 20px;
    max-width: 25%;

    border-radius: 5px;
    height: auto;
}