.event-item-contents h3 {
    margin-top: 30px;
}

.event-item-contents p img, .event-item-contents div img {
    display: block;
    margin-left: auto;
    margin-right: auto;

    margin-bottom: 40px;
}

.event-item-contents pre {
    border: 1px solid #f0f0f0;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 15px 20px;


    display: inline-block;
    width: 47%;

    vertical-align: top;
    margin-bottom: 15px;
}

.event-item-contents pre.right-side {
    clear: right;
}


div.btn-group button.btn:focus {
    box-shadow: none !important;
    outline: none !important;
}



div.task-analysis-solution-source-code .CodeMirror {
    height: 400px;
    height: auto !important;
}
