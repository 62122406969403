.submission_test_style {
    background-color: #ffffff;

    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;

    width: 90%;
    max-width: 900px;

    height: auto;
    max-height: 77vh;

    border-radius: 3px;
    padding: 30px 20px;

    overflow-y: auto;
}

.submission_test_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1001;
    background-color: #353535;
}

.submission_test_style div.card pre {
    margin-bottom: 1px;
    overflow-y: auto;
    overflow-x: auto;

    white-space: pre-wrap;
}

.submission_test_style div.card {
    margin-bottom: 29px;
}