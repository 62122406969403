.upload_modal_style {
    background-color: #ffffff;

    margin-top: 230px;
    margin-left: auto;
    margin-right: auto;

    width: 90%;
    max-width: 600px;

    height: auto;
    border-radius: 3px;
    padding: 30px 20px;
}

.upload_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1001;
    background-color: #353535;
}
