html, body, #root {
    height: 100%;
}

div.cool-background-image {
    background: linear-gradient(
            rgba(250, 250, 250, 0.97),
            rgba(250, 250, 250, 0.97)
    ),
    url("/images/bg-computer.jpg");

    background-size: cover;
    background-repeat: no-repeat;

}

.data-form, .auth-form, .code-authorization-form {
    padding: 30px 21px;
}

.data-form p.form-error, .auth-form p.form-error, .code-authorization-form p.form-error {
    font-weight: bold;
    color: red;

    margin: 30px 0 20px 0;
}

.data-form div.form-group {
    margin-bottom: 30px;
}

.data-form div.form-group label {
    margin-bottom: 0.25rem;
}