.confirm_modal_style {
    background-color: #ffffff;

    margin-top: 230px;
    margin-left: auto;
    margin-right: auto;

    width: 90%;
    max-width: 600px;

    height: auto;
    border-radius: 3px;
    padding: 30px 20px;
}

.confirm_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1001;
    background-color: #353535;
}

html, body, #root {
    height: 100%;
}

div.cool-background-image {
    background: -webkit-linear-gradient(
            rgba(250, 250, 250, 0.97),
            rgba(250, 250, 250, 0.97)
    ),
    url("/images/bg-computer.jpg");
    background: linear-gradient(
            rgba(250, 250, 250, 0.97),
            rgba(250, 250, 250, 0.97)
    ),
    url("/images/bg-computer.jpg");

    background-size: cover;
    background-repeat: no-repeat;

}

.data-form, .auth-form, .code-authorization-form {
    padding: 30px 21px;
}

.data-form p.form-error, .auth-form p.form-error, .code-authorization-form p.form-error {
    font-weight: bold;
    color: red;

    margin: 30px 0 20px 0;
}

.data-form div.form-group {
    margin-bottom: 30px;
}

.data-form div.form-group label {
    margin-bottom: 0.25rem;
}
.landing-photo {
    width: 90%;
    max-width: 750px;

    margin-top: 30px;
    margin-bottom: 70px;
}

.bg-fill {
    height: 100%;
    padding-top: 90px;

    color: black;
}

.bg-fill a.btn.btn-lg {
    margin-bottom: 15px;
}

img.small-intro-img {
    width: 170px;
    margin: 20px 20px 50px 20px;
    max-width: 25%;

    border-radius: 5px;
    height: auto;
}
.sk-folding-cube {
    margin: 20px auto;
    width: 60px;
    height: 60px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0071e3;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
    0%, 10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%, 75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%, 100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

@keyframes sk-foldCubeAngle {
    0%, 10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%, 75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%, 100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}
.teacher-sidebar {
    padding-right: 15px;
}

.teacher-sidebar ul a.list-group-item {
    color: #212529 !important;
}

.teacher-sidebar ul .badge {
    display: none;
}

.teacher-sidebar ul a.list-group-item.active {
    color: white !important;
}



.rich-text-editor {
    min-height: 500px;
}
.rich-text-editor {
    min-height: 500px;
}

p.parsing-info.parsing-info-jump {
    margin-top: 35px !important;
}
.upload_modal_style {
    background-color: #ffffff;

    margin-top: 230px;
    margin-left: auto;
    margin-right: auto;

    width: 90%;
    max-width: 600px;

    height: auto;
    border-radius: 3px;
    padding: 30px 20px;
}

.upload_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1001;
    background-color: #353535;
}

.rich-text-editor {
    min-height: 500px;
}
div.submission-container .CodeMirror {
    min-height: 65vh;
}
.submission_test_style {
    background-color: #ffffff;

    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;

    width: 90%;
    max-width: 900px;

    height: auto;
    max-height: 77vh;

    border-radius: 3px;
    padding: 30px 20px;

    overflow-y: auto;
}

.submission_test_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1001;
    background-color: #353535;
}

.submission_test_style div.card pre {
    margin-bottom: 1px;
    overflow-y: auto;
    overflow-x: auto;

    white-space: pre-wrap;
}

.submission_test_style div.card {
    margin-bottom: 29px;
}
table.table-event-items a {
    color: #212529 !important;
}
.student-event-sidebar {
    padding-right: 15px;
}

.student-event-sidebar ul a.list-group-item {
    color: #212529 !important;
}

.student-event-sidebar ul .badge {
    display: none;
}

.student-event-sidebar ul a.list-group-item.active {
    color: white !important;
}



.event {
    border: 1px solid #cecece;
    padding: 10px 15px;

    border-radius: 3px;
    margin-bottom: 30px;
}

.event-running {
    background-color: #f7fff7;
}

div.submission-container .CodeMirror {
    min-height: 65vh;
}
.question_modal_style {
    background-color: #ffffff;

    margin-top: 230px;
    margin-left: auto;
    margin-right: auto;

    width: 90%;
    max-width: 600px;

    height: auto;
    border-radius: 3px;
    padding: 30px 20px;
}

.question_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1001;
    background-color: #353535;
}

.event-item-contents h3 {
    margin-top: 30px;
}

.event-item-contents p img, .event-item-contents div img {
    display: block;
    margin-left: auto;
    margin-right: auto;

    margin-bottom: 40px;
}

.event-item-contents pre {
    border: 1px solid #f0f0f0;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 15px 20px;


    display: inline-block;
    width: 47%;

    vertical-align: top;
    margin-bottom: 15px;
}

.event-item-contents pre.right-side {
    clear: right;
}


div.btn-group button.btn:focus {
    box-shadow: none !important;
    outline: none !important;
}



div.task-analysis-solution-source-code .CodeMirror {
    height: 400px;
    height: auto !important;
}

