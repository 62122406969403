.teacher-sidebar {
    padding-right: 15px;
}

.teacher-sidebar ul a.list-group-item {
    color: #212529 !important;
}

.teacher-sidebar ul .badge {
    display: none;
}

.teacher-sidebar ul a.list-group-item.active {
    color: white !important;
}


