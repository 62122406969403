.event {
    border: 1px solid #cecece;
    padding: 10px 15px;

    border-radius: 3px;
    margin-bottom: 30px;
}

.event-running {
    background-color: #f7fff7;
}
